<template>
<div id="PostDetails">

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<v-container>
<div>

    <v-row align="center" justify="center">
    <v-col cols="12" sm="12" md="6" class="my-5">
        <v-img :src="PostData.imageUrl" height="400px" alt="Event Image" class="elevation-10 white animated fadeIn slow">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
                </v-row>
            </template>
        </v-img>
    </v-col>
    </v-row>

    <div class="mt-5">
        <p class="subtitle-1">Published : {{PostData.DOE}}</p>
        <p class="headline py-5 my-5">{{PostData.title}}</p>
    </div>

    <p class="my-5 subtitle-1" v-html="PostData.description"></p>

    <div class="py-5 headline" v-if="CommentData || CommentDataSmall" >
        Comments <hr class="mt-5">
    </div>

    <div class="py-3 animated fadeIn slower" v-if="CommentDataSmall && !loadMore">
        <v-container>
        <div v-for="comment in CommentDataSmall" :key="comment.addedAt.toString()">
            <v-lazy v-model="isActive" :options="{ threshold: .5 }" transition="fade-transition">
            <v-card class="my-5 pa-3 animated fadeIn slower">
                <v-card-title class="title elevation-0 pa-3 blue-grey lighten-5">{{comment.name}}</v-card-title>
                <div class="my-3 pa-0 blockquote ml-2" style="border-left: 5px solid #f00;border-radius: 0">
                    <v-card-text class="subtitle-1 text--primary">{{comment.comment}}
                    </v-card-text>
                </div>
            </v-card>
            </v-lazy>
        </div>
        </v-container>
    </div>

    <div class="py-5 animated fadeIn slower" v-if="CommentData && loadMore">
        <v-container>
        <div v-for="comment in CommentData" :key="comment.addedAt.toString()">
            <v-lazy v-model="isActive" :options="{ threshold: .5 }" transition="fade-transition">
            <v-card class="my-5 pa-3 animated fadeIn slower">
                <v-card-title class="title elevation-0 pa-3 blue-grey lighten-5">{{comment.name}}</v-card-title>
                <div class="my-3 pa-0 blockquote ml-2" style="border-left: 5px solid #f00;border-radius: 0">
                    <v-card-text class="subtitle-1 text--primary">{{comment.comment}}
                    </v-card-text>
                </div>
            </v-card>
            </v-lazy>
        </div>
        </v-container>
    </div>

    <div class="mb-5 d-flex align-center justify-center">
        <v-btn rounded v-if="CommentDataSmall.length < CommentData.length" @click="LoadMoreFun()" class="primary">
            <span v-if="!loadMore">Show More</span>
            <span v-if="loadMore">Show Less</span>
            <v-icon class="ml-3">mdi-sync mdi-18px</v-icon>
        </v-btn>
    </div>

</div>
</v-container>

























    <v-btn class="secondary" @click="$router.go(-1)">go back</v-btn>
    <v-btn color="primary " @click="$router.push('/editpost/' + PostSlug)">Edit</v-btn>
    <v-btn color="red darken-1 " @click="deleteDialog = true">Delete</v-btn>

    <!-- delete modal -->
    <v-dialog v-model="deleteDialog" persistent max-width="290" transition="slide-y-transition">
    <v-card>
    <v-card-title class="headline">Confirm Delete?</v-card-title>
    <v-card-text>
        This action can't be undone!
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
        <v-btn color="primary" text @click="DeletePost()">Delete</v-btn>
    </v-card-actions>
    </v-card>
    </v-dialog>
    <!-- delete dialog ends -->
</div>
</template>

<script>
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

export default {

    name: 'PostDetails',

    metaInfo: {
        title: 'Posts',
    },

    mounted(){this.$store.dispatch('getComments');},

    
    data(){return{

        deleteDialog: false,
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,
        name: '',comment: '',loadMore: false,isActive: false,
    }},
    
    computed: {

        PostSlug(){         
          return this.$route.params.slug != null ? this.$route.params.slug : null;
        },

        PostData(){
            if(this.PostSlug != null) {
                let data =  this.$store.getters.GetPostWithSlug(this.PostSlug);
                return data.length != 0 ? data[0] : this.$router.push("/posts");
            }else{ return this.$router.push("/posts"); }             
        },

        CommentData(){
            if(this.PostSlug != null){
                let data = this.$store.getters.GetCommentsWithSlug(this.PostSlug);
                return data.length != 0 ? data : false;
            }else{return false;}
        },

        CommentDataSmall(){
             if(this.PostSlug != null){
                let data = this.$store.getters.GetCommentsWithSlug(this.PostSlug);
                return data.length != 0 ? data.slice(0, 2) : false;
            }else{return false;}
        }
        
    },

    methods: {

        async DeletePost(){
            this.deleteDialog = false;
            // get image url
            let url
            if(this.PostData.imageUrl){
                url = this.PostData.imageUrl.toString();
            }
            let slug = this.PostSlug;
            // get document where student id equals selected one
            await firebase.firestore().collection("posts")
            .where("slug", "==", slug).get().then(
            (doc) => {

                // delete post and comment
                firebase.firestore().collection("posts").doc(doc.docs[0].id).delete()
                .then(
                (result) => { 
                    // delete image
                    if(url){ firebase.storage().refFromURL(url).delete();}
                    // batch delete
                    this.BatchDelete(slug);
                });
                
            }).catch((err) => {console.log(err)}); 
        },

        // batch delete all comments
        async BatchDelete(slug){
             // delete all comments
            // First perform the query
            firebase.firestore().collection("comments").where("slug","==", slug).get()
            .then((querySnapshot) => {

                if(querySnapshot.docs.length != 0){

                    // Once we get the results, begin a batch
                    var batch = firebase.firestore().batch();
                    querySnapshot.forEach((doc) => {
                        // For each doc, add a delete operation to the batch
                        batch.delete(doc.ref);
                    });
                    // Commit the batch
                    batch.commit().then(this.$router.push('/posts'));
                }else{this.$router.push('/posts')}

            });
        }

    }


}
</script>

<style lang="scss" scoped>

</style>